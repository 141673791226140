import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, TextField, Typography, Divider, IconButton, InputAdornment } from '@mui/material';
import axios from 'axios';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import '../styles/UserSettings.css';

const UserSettings = () => {
    const { username: urlUsername } = useParams();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${urlUsername}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setUsername(response.data.username);
                setEmail(response.data.email || '');
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchUserData();
    }, [urlUsername]);

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const validatePassword = (password) => {
        const passwordPattern = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
        return passwordPattern.test(password);
    };

    const handleSaveChanges = async () => {
        const token = localStorage.getItem('token');

        // Reset messages
        setErrorMessage('');
        setSuccessMessage('');

        // Validate email format
        if (!validateEmail(email)) {
            setErrorMessage("Please enter a valid email address.");
            return;
        }

        // If updating the password, check old password and new password requirements
        if (newPassword || confirmPassword || oldPassword) {
            if (!oldPassword) {
                setErrorMessage("Please enter your old password to change your password.");
                return;
            }
            if (newPassword !== confirmPassword) {
                setErrorMessage("New passwords do not match.");
                return;
            }
            if (!validatePassword(newPassword)) {
                setErrorMessage("New password must be at least 8 characters long, contain at least one uppercase letter, and one number.");
                return;
            }
        }

        const data = {
            username,
            email,
            oldPassword,
            newPassword,
        };

        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/users/${urlUsername}`, data, {
                headers: { Authorization: `Bearer ${token}` }
            });

            localStorage.setItem('token', response.data.access_token);

            setSuccessMessage('Your information has been updated successfully.');
            setErrorMessage('');

            setUsername(data.username);
            setEmail(data.email);
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } catch (error) {
            if (error.response) {
                const serverError = error.response.data.error || '';

                // Check for specific duplicate key error messages
                if (serverError.includes('duplicate key value violates unique constraint "users_username_key"')) {
                    setErrorMessage('This username is already taken.');
                } else if (serverError.includes('duplicate key value violates unique constraint "users_email_key"')) {
                    setErrorMessage('This email is already registered.');
                } else if (error.response.status === 401) {
                    setErrorMessage('Old password is incorrect.');
                } else {
                    setErrorMessage('Error updating your information.');
                }
            } else {
                setErrorMessage('Error updating your information.');
            }
            setSuccessMessage('');
        }
    };

    const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);
    const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    return (
        <div className="fadein">
            <div className="user-settings-container">
                <Typography variant="h4" className="settings-title">Update Your Information</Typography>

                {/* Personal Information Section */}
                <div className="settings-section">
                    <Typography variant="h6">Change Personal Information</Typography>
                    <TextField
                        label="Username"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        label="Email"
                        placeholder="Enter your email address"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                {/* Divider */}
                <Divider style={{ margin: '20px 0' }} />

                {/* Password Update Section */}
                <div className="settings-section">
                    <Typography variant="h6">Change Password</Typography>
                    <TextField
                        label="Old Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        autoComplete="off"
                    />
                    <TextField
                        label="New Password"
                        type={showNewPassword ? 'text' : 'password'}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <div className="icon-button-wrapper">
                                        <IconButton
                                            onClick={toggleShowNewPassword}
                                            edge="end"
                                        >
                                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </div>
                                </InputAdornment>
                            )
                        }}
                    />
                    <TextField
                        label="Confirm New Password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <div className="icon-button-wrapper">
                                        <IconButton
                                            onClick={toggleShowConfirmPassword}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </div>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Button variant="contained" color="primary" fullWidth onClick={handleSaveChanges} className='information-change-button-submit'>
                        Save Changes
                    </Button>
                </div>

                {/* Display Success or Error Messages */}
                {successMessage && <Typography className="success-message">{successMessage}</Typography>}
                {errorMessage && <Typography className="error-message">{errorMessage}</Typography>}
            </div>
        </div>
    );
};

export default UserSettings;
