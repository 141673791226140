import React, { useState } from 'react';
import { Typography, Button } from '@mui/material';
import '../styles/Home.css';

const Questions = ({ currentQuestion, handleAnswer }) => {
    const isEven = currentQuestion.options.length % 2 === 0;
    const [selectedValues, setSelectedValues] = useState([]);

    // Toggle selection for multi-select options
    const handleSelect = (value) => {
        setSelectedValues((prev) => {
            if (prev.includes(value)) {
                return prev.filter((v) => v !== value); // Remove if already selected
            }
            return [...prev, value]; // Add if not selected
        });
    };

    // Submit selected values for multi-select questions
    const submitAnswer = () => {
        handleAnswer(selectedValues.join('|')); // Join selected values with '|'
    };

    // Determine if the button should be active (red) based on selection
    const isSelected = (value) => selectedValues.includes(value);

    return (
        <div className="fadein">
            <Typography variant="h5" className="quiz-question" color="secondary">
                {currentQuestion.question}
            </Typography>
            <div className={`quiz-options ${isEven ? 'even-options' : 'odd-options'}`}>
                {currentQuestion.options.map((option, index) => (
                    <Button
                        key={index}
                        onClick={() => currentQuestion.multiple ? handleSelect(currentQuestion.values[index]) : handleAnswer(currentQuestion.values[index])}
                        className={`quiz-option ${isSelected(currentQuestion.values[index]) ? 'selected' : ''} ${!isEven && index === currentQuestion.options.length - 1 ? 'last-option' : ''}`}
                        style={{
                            backgroundColor: isSelected(currentQuestion.values[index]) ? 'red' : '',
                        }}
                    >
                        {option}
                    </Button>
                ))}
            </div>
            {currentQuestion.multiple && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={submitAnswer}
                    className="next-question-btn"
                    disabled={selectedValues.length === 0} // Disable button if no selection
                    style={{
                        backgroundColor: selectedValues.length > 0 ? '#E50914' : 'gray',
                        cursor: selectedValues.length > 0 ? 'pointer' : 'not-allowed'
                    }}
                >
                    Next Question
                </Button>
            )}
        </div>
    );
};

export default Questions;
