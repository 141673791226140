import React from 'react';
import { Container, Typography } from '@mui/material';
import '../styles/Legal.css';

const Privacy = () => {
    return (
        <div className="fadein">
            <Container maxWidth="sm" className="legal-container">
                <Typography variant="h4" gutterBottom className="legal-container-text">
                    Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    Your privacy is important to us. This privacy policy explains how we collect, use, and share your personal information. We are committed to ensuring that your personal data is protected.
                </Typography>
                <Typography variant="body1" paragraph>
                    By using our services, you agree to the collection and use of information in accordance with this policy. For more details, please review our full privacy policy.
                </Typography>
            </Container>
        </div>
    );
};

export default Privacy;
