import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../styles/Recommended.css';  // Link the CSS
import '../index.css';  // Link the CSS
import { useLoading } from '../context/LoadingContext';

const Recommended = () => {
    const [recommendedList, setRecommendedList] = useState([]);
    const { loading, setLoading } = useLoading();
    const userId = localStorage.getItem('user_id');

    useEffect(() => {
        const fetchRecommended = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${userId}/recommended`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setRecommendedList(response.data);
            } catch (error) {
                console.error('Error fetching recommended media:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchRecommended();
    }, [userId]);

    return (
        <div className="fadein">
            <div>
                {loading ? (
                    <div className="loader-container">
                    </div>
                ) : (
                    <div className="recommended-container">
                        <h1 className="recommended-title">My Recommendations</h1>
                        {recommendedList.length === 0 ? (
                            <p className="empty-message">You haven't haven't completed a quiz yet! Start the quiz and track your last recommendations here.</p>
                        ) : (
                            <div className="recommended-grid">
                                {recommendedList.map((media, index) => (
                                    <Link to={`/media/${media.media_id}?type=${media.media_type}`} key={media.media_id}>
                                        <div className="recommended-card">
                                            <img
                                                src={`https://image.tmdb.org/t/p/w500${media.poster_path}`}
                                                alt={media.title || media.name}
                                                className="recommended-poster"
                                            />
                                            <div className="recommended-content">
                                                <h6 className="recommended-title-text">
                                                    {media.title || media.name}
                                                </h6>
                                                <p className="recommended-date">
                                                    Recommended on {new Date(media.recommended_at).toLocaleDateString()}
                                                </p>
                                            </div>
                                            {/* Display the "Recommended" bubble for each item */}
                                            <div className="recommendation-bubble">Recommended</div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Recommended;
