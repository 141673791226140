import React from 'react';
import { Container, Typography } from '@mui/material';
import '../styles/Legal.css';

const Cookies = () => {
    return (
        <div className="fadein">
            <Container maxWidth="sm" className="legal-container">
                <Typography variant="h4" gutterBottom className="legal-container-text">
                    Cookies Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    We use cookies to enhance your experience on our platform. By continuing to browse, you agree to the use of cookies. Cookies help us improve functionality, track usage, and personalize your experience.
                </Typography>
                <Typography variant="body1" paragraph>
                    You can manage your cookie preferences in your browser settings. Please note that disabling certain cookies may affect your ability to use some features of our site.
                </Typography>
            </Container>
        </div>
    );
};

export default Cookies;
