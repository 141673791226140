import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useLoading } from '../context/LoadingContext';
import '../styles/WatchHistory.css'; // Assuming this CSS file exists

const WatchHistory = () => {
    const [watchHistory, setWatchHistory] = useState([]);
    const { loading, setLoading } = useLoading();
    const userId = localStorage.getItem('user_id');

    // Fetch watch history
    useEffect(() => {
        const fetchWatchHistory = async () => {
            setLoading(true); // Start loading
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${userId}/watch-history`, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                // Sort the watch history by watched_at in descending order (latest first)
                const sortedHistory = response.data.sort((a, b) => new Date(b.watched_at) - new Date(a.watched_at));
                setWatchHistory(sortedHistory);
            } catch (error) {
                console.error('Error fetching watch history:', error);
            } finally {
                setLoading(false); // Stop loading after data fetch
            }
        };

        fetchWatchHistory();
    }, [userId]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
    };

    return (
        <div className="fadein">
            <div>
                {loading ? (
                    <div className="loader-container">
                    </div>
                ) : (
                    <div className="watchhistory-container">
                        <h1 className="watchhistory-title">My History</h1>
                        {watchHistory.length === 0 ? (
                            <p className="empty-message">You haven't watched anything yet! Start adding stuff to your history and track your journey here.</p>
                        ) : (
                            <div className="watchhistory-grid">
                                {watchHistory.map((media) => (
                                    <Link to={`/media/${media.media_id}?type=${media.media_type}`} key={media.media_id}>
                                        <div className="watchhistory-card">
                                            <img
                                                src={`https://image.tmdb.org/t/p/w500${media.poster_path}`}
                                                alt={media.title || media.name}
                                                className="watchhistory-poster"
                                            />
                                            <div className="watchhistory-content">
                                                <h6 className="watchhistory-title-text">
                                                    {media.title || media.name}
                                                </h6>
                                                <p className="watchhistory-date">
                                                    Added to the list on {formatDate(media.watched_at)}
                                                </p>
                                            </div>
                                            <div className="seen-bubble">Seen!</div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default WatchHistory;
