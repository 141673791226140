import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../styles/WatchLater.css'; // Assuming this CSS file exists
import { useLoading } from '../context/LoadingContext';

const WatchLater = () => {
    const [watchLaterList, setWatchLaterList] = useState([]);
    const [seenMedia, setSeenMedia] = useState([]);
    const { loading, setLoading } = useLoading();
    const userId = localStorage.getItem('user_id');

    useEffect(() => {
        fetchWatchLater();
        fetchSeenHistory();
    }, [userId]);

    const fetchWatchLater = async () => {
        setLoading(true);  // Start loading
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${userId}/watch-later`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            // Sort the watch later list by added_at in descending order (latest first)
            const sortedWatchLater = response.data.sort((a, b) => new Date(b.added_at) - new Date(a.added_at));
            setWatchLaterList(sortedWatchLater);
        } catch (error) {
            console.error('Error fetching watch later list:', error);
        } finally {
            setLoading(false);  // Stop loading once data is fetched
        }
    };

    const fetchSeenHistory = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${userId}/watch-history`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const seenIds = response.data.map(media => media.media_id);
            setSeenMedia(seenIds);
        } catch (error) {
            console.error('Error fetching watch history:', error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
    };

    return (
        <div className="fadein" >
            {loading ? (
                <div className="loader-container">
                </div>
            ) : (
                <div className="watchlater-container">
                    <h1 className="watchlater-title">My Watchlist</h1>
                    {watchLaterList.length === 0 ? (
                        <p className="empty-message">You haven't added anything to your watchlist yet! Start exploring and track your journey here.</p>
                    ) : (
                        <div className="watchlater-grid">
                            {watchLaterList.map((media) => (
                                <Link to={`/media/${media.media_id}?type=${media.media_type}`} key={media.media_id}>
                                    <div className="watchlater-card">
                                        <img
                                            src={`https://image.tmdb.org/t/p/w500${media.poster_path}`}
                                            alt={media.title || media.name}
                                            className="watchlater-poster"
                                        />
                                        <div className="watchlater-content">
                                            <h6 className="watchlater-title-text">
                                                {media.title || media.name}
                                            </h6>
                                            <p className="watchlater-date">
                                                Added to the list on {formatDate(media.added_at)}
                                            </p>
                                        </div>
                                        {seenMedia.includes(media.media_id) && (
                                            <div className="seen-bubble">Seen!</div>
                                        )}
                                    </div>
                                </Link>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default WatchLater;
