import { Button } from '@mui/material';
import '../styles/Profile.css';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const Profile = ({ username, onLogout }) => {
    const navigate = useNavigate();
    const { username: profileUsername } = useParams(); // Get username from URL params

    // Check if the logged-in user is the same as the profile being accessed
    useEffect(() => {
        const token = localStorage.getItem('token');
        const storedUsername = localStorage.getItem('username');
        if (!token || storedUsername !== profileUsername) {
            navigate('/login'); // Redirect to login if unauthorized or token is missing
        }
    }, [profileUsername, navigate]);

    return (
        <div className="fadein">
            <div className="profile-container">
                <h1 className="profile-title">Welcome, {username}</h1>
                <div className="profile-buttons">
                    <Button variant="contained" color="primary" className="profile-buttons-info" onClick={() => navigate(`/user-settings/${username}`)}>
                        Change information
                    </Button>
                    <Button variant="contained" color="primary" className="profile-buttons-recommended" onClick={() => navigate('/recommended')}>
                        My Recommended
                    </Button>
                    <Button variant="contained" color="primary" className="profile-buttons-watchlist" onClick={() => navigate('/watchlist')}>
                        My Watchlist
                    </Button>
                    <Button variant="contained" color="primary" className="profile-buttons-history" onClick={() => navigate('/history')}>
                        My History
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Profile;
