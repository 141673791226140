import React from 'react';
import '../styles/Loader.css';
import logo from '../icons/ymo-logo.png';

const Loader = () => (
    <div className="loader-container">
        <img src={logo} alt="Loading..." className="loader-logo" />
        <p>Loading...</p>
    </div>
);

export default Loader;
