import React, { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import axios from 'axios';
import Questions from '../components/Questions';
import questions from '../components/QuestionsData';
import { useNavigate, useLocation } from 'react-router-dom';
import Results from './Results';

const Quiz = () => {
    const [answers, setAnswers] = useState({});
    const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
    const [visibleItems, setVisibleItems] = useState(20);
    const userId = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const currentQuestionIndex = parseInt(queryParams.get('questionIndex') || '0', 10);

    const updateURL = (index) => {
        navigate(`?questionIndex=${index}`);
    };

    const handleAnswer = (value) => {
        const currentQuestion = questions[currentQuestionIndex];
        setAnswers((prev) => {
            const updatedAnswers = { ...prev, [currentQuestion.key]: value.includes("all") ? "" : value };
            const nextIndex = getNextQuestionIndex(currentQuestionIndex, updatedAnswers);

            if (nextIndex < questions.length) {
                updateURL(nextIndex);
            } else {
                setAllQuestionsAnswered(true);
                updateURL('results');
            }

            return updatedAnswers;
        });
    };


    const getNextQuestionIndex = (index, updatedAnswers) => {
        let nextIndex = index + 1;
        while (nextIndex < questions.length) {
            const nextQuestion = questions[nextIndex];
            if (
                (nextQuestion.requiresLogin && !userId) ||
                (nextQuestion.condition && !nextQuestion.condition(updatedAnswers))
            ) {
                nextIndex++;
            } else {
                return nextIndex;
            }
        }
        return questions.length;
    };

    useEffect(() => {
        const handlePopState = (event) => {
            const questionIndex = parseInt(new URLSearchParams(window.location.search).get('questionIndex'), 10);
            if (!isNaN(questionIndex)) {
                setAllQuestionsAnswered(false);
                navigate(`?questionIndex=${questionIndex}`, { replace: true });
            } else {
                setAllQuestionsAnswered(true);
            }
        };

        window.addEventListener('popstate', handlePopState);
        return () => window.removeEventListener('popstate', handlePopState);
    }, [navigate]);

    return (
        <div className="fadein">
            <Container maxWidth="sm" className="quiz-container">
                {!allQuestionsAnswered ? (
                    <Questions
                        currentQuestion={questions[currentQuestionIndex]}
                        handleAnswer={handleAnswer}
                        className="quiz-question"
                    />
                ) : (
                    <Results
                        answers={answers}
                        visibleItems={visibleItems}
                        setVisibleItems={setVisibleItems}
                        userId={userId}
                        token={token}
                    />
                )}
            </Container>
        </div>
    );
};

export default Quiz;
