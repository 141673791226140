import React, { useState } from 'react';
import { TextField, Button, Container, Typography } from '@mui/material';
import { loginUser, setAuthToken } from '../services/api';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../styles/Login.css';

const Login = ({ onLogin }) => {
    const [formData, setFormData] = useState({ username: '', password: '' });
    const navigate = useNavigate(); // Initialize navigate

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await loginUser(formData);
            const token = response.data.access_token;
            localStorage.setItem('token', token);
            setAuthToken(token);
            onLogin(response.data);
            console.log('User logged in:', response.data);

            // Redirect to home page after login
            navigate('/');  // Redirects to the home page
        } catch (error) {
            console.error('Error logging in:', error);
        }
    };

    return (
        <div className="fadein">
            <Container maxWidth="sm" className="login-container">
                <form onSubmit={handleSubmit} className="login-form">
                    <TextField
                        label="Username"
                        variant="outlined"
                        fullWidth
                        name="username"
                        onChange={handleChange}
                        className="login-field" // Ensure this class is present
                        sx={{ marginBottom: '20px' }}
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        type="password"
                        fullWidth
                        name="password"
                        onChange={handleChange}
                        className="login-field" // Ensure this class is present
                        sx={{ marginBottom: '20px' }}
                    />
                    <Button variant="contained" color="primary" type="submit" className="login-button">
                        Log in
                    </Button>

                    <Button variant="contained" color="primary" onClick={() => navigate('/register')} className="login-button">
                        Register
                    </Button>
                </form>
            </Container>
        </div>
    );
};

export default Login;
