import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/Media.css';
import eyeIcon from '../icons/eye.svg';
import eyeOffIcon from '../icons/eye-off.svg';
import clockIcon from '../icons/clock.svg';
import clockCheckIcon from '../icons/clock-check.svg';
import { useLoading } from '../context/LoadingContext';

const Media = ({ language }) => {
    const { mediaId } = useParams();
    const [mediaDetails, setMediaDetails] = useState(null);
    const { loading, setLoading } = useLoading();
    const [showTrailer, setShowTrailer] = useState(false);
    const [isInWatchLater, setIsInWatchLater] = useState(false);
    const [isInWatchHistory, setIsInWatchHistory] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const mediaType = queryParams.get('type') || 'movie';
    const userId = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');

    useEffect(() => {
        console.log("Language for media fetch:", language); // Debugging line
        fetchMediaDetails();
        if (userId && token) {
            checkWatchLater();
            checkWatchHistory();
        }
    }, [mediaId, mediaType, language]);

    const fetchMediaDetails = async () => {
        try {
            setLoading(true);

            console.log("Fetching details with language:", language); // Debugging line
            const detailsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/tmdb/media/${mediaId}`, {
                params: { type: mediaType, language }
            });

            const videoResponse = await axios.get(`${process.env.REACT_APP_API_URL}/tmdb/media/${mediaId}/videos`, {
                params: { type: mediaType, language }
            });

            setMediaDetails({
                ...detailsResponse.data,
                trailerKey: videoResponse.data.results?.find(video => video.type === 'Trailer' && video.site === 'YouTube')?.key || null
            });

            setLoading(false);
        } catch (error) {
            console.error('Error fetching media details:', error);
            setLoading(false);
        }
    };

    const formatRuntime = (runtime) => {
        const hours = Math.floor(runtime / 60);
        const minutes = runtime % 60;
        return `${hours}h ${minutes}m`;
    };

    const checkWatchLater = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${userId}/watch-later`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const isInList = response.data.some(media => media.media_id === parseInt(mediaId));
            setIsInWatchLater(isInList);
        } catch (error) {
            console.error('Error checking watch later:', error);
        }
    };

    const checkWatchHistory = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${userId}/watch-history`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const isInList = response.data.some(media => media.media_id === parseInt(mediaId));
            setIsInWatchHistory(isInList);
        } catch (error) {
            console.error('Error checking watch history:', error);
        }
    };


    const handleToggleWatchLater = async () => {
        const payload = { media_id: mediaId, media_type: mediaType };
        const requestUrl = `${process.env.REACT_APP_API_URL}/users/${userId}/watch-later`;

        try {
            if (isInWatchLater) {
                await axios.delete(requestUrl, {
                    data: payload,
                    headers: { Authorization: `Bearer ${token}` }
                });
                setIsInWatchLater(false);
                alert('Removed from Watch Later');
            } else {
                await axios.post(requestUrl, payload, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setIsInWatchLater(true);
                alert('Added to Watch Later');
            }
        } catch (error) {
            console.error('Error updating Watch Later list:', error);
        }
    };

    const handleToggleWatchHistory = async () => {
        const payload = { media_id: mediaId, media_type: mediaType };
        const requestUrl = `${process.env.REACT_APP_API_URL}/users/${userId}/watch-history`;

        try {
            if (isInWatchHistory) {
                await axios.delete(requestUrl, {
                    data: payload,
                    headers: { Authorization: `Bearer ${token}` }
                });
                setIsInWatchHistory(false);
                alert('Removed from Watch History');
            } else {
                await axios.post(requestUrl, payload, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setIsInWatchHistory(true);
                alert('Added to Watch History');
            }
        } catch (error) {
            console.error('Error updating Watch History list:', error);
        }
    };

    if (loading) {
        return <div className="loader-container">
        </div>;
    }

    if (!mediaDetails) {
        return <div>Media details are not available.</div>;
    }

    const handlePosterClick = () => {
        if (mediaDetails.trailerKey) {
            setShowTrailer(true);
        } else {
            alert('Trailer not available.');
        }
    };

    const castMembers = mediaDetails.cast?.slice(0, 6) || [];

    return (
        <div className="fadein">
            <div className="media-container">
                <h1 className="media-title">{mediaDetails.title || mediaDetails.name} ({(mediaDetails.release_date || mediaDetails.first_air_date || '').slice(0, 4)})</h1>
                <div className="media-actions">
                    {token && userId && (
                        <>
                            {/* Move the Watch History button to the left */}
                            <div
                                className="action-icon-wrapper"
                                data-tooltip={isInWatchHistory ? "Click here to remove this from your viewing history" : "Click here if you've seen this before"}
                                onClick={handleToggleWatchHistory}
                            >
                                <img
                                    src={isInWatchHistory ? eyeIcon : eyeOffIcon}
                                    alt={isInWatchHistory ? "Remove from Watch History" : "Add to Watch History"}
                                    className="action-icon"
                                />
                            </div>

                            {/* Move the Watch Later button to the right */}
                            <div
                                className="action-icon-wrapper"
                                data-tooltip={isInWatchLater ? "Click here to remove this from the Watch Later list" : "Click here to add this to your Watch Later list"}
                                onClick={handleToggleWatchLater}
                            >
                                <img
                                    src={isInWatchLater ? clockCheckIcon : clockIcon}
                                    alt={isInWatchLater ? "Remove from Watch Later" : "Add to Watch Later"}
                                    className="action-icon"
                                />
                            </div>
                        </>
                    )}
                </div>
                <div className="media-layout">
                    {showTrailer && mediaDetails.trailerKey ? (
                        <iframe
                            className="media-trailer"
                            src={`https://www.youtube.com/embed/${mediaDetails.trailerKey}?rel=0&modestbranding=1&vq=hd1080`}
                            title="YouTube trailer"
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    ) : (
                        <div className="poster-wrapper" onClick={mediaDetails.trailerKey ? handlePosterClick : null}>
                            <img
                                src={`https://image.tmdb.org/t/p/w500${mediaDetails.poster_path}`}
                                alt={mediaDetails.title || mediaDetails.name}
                                className="media-poster"
                            />
                            {/* Show the Seen! bubble if the media is in the Watch History */}
                            {isInWatchHistory && <div className="seen-bubble">Seen!</div>}
                            <div className="play-overlay">
                                {mediaDetails.trailerKey ? (
                                    <>
                                        <span className="play-button">▶</span>
                                        <span className="play-text">Click to watch trailer</span>
                                    </>
                                ) : (
                                    <span className="play-text">Trailer not available at this moment.</span>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="media-details">
                        {mediaType === 'movie' ? (
                            <>
                                <p className="media-info">Runtime: {mediaDetails.runtime ? formatRuntime(mediaDetails.runtime) : 'N/A'}</p>
                                <p className="media-info">Director: {mediaDetails.crew?.find(crewMember => crewMember.job === 'Director')?.name || 'N/A'}</p>
                            </>
                        ) : (
                            <>
                                <p className="media-info">Total Seasons: {mediaDetails.number_of_seasons || 'N/A'}</p>
                                <p className="media-info">Total Episodes: {mediaDetails.number_of_episodes || 'N/A'}</p>
                            </>
                        )}

                        <p className="media-info">Genres: {mediaDetails.genres?.map(genre => genre.name).join(', ') || 'Not available in this language.'}</p>
                        <p className="media-overview">{mediaDetails.overview || 'No overview available in this language.'}</p>

                        <h3>Cast</h3>
                        <div className="cast-list">
                            {castMembers.length > 0 ? (
                                castMembers.map((cast) => (
                                    <div key={cast.id} className="cast-member">
                                        <img src={`https://image.tmdb.org/t/p/w200${cast.profile_path}`} alt={cast.name} />
                                        <p>{cast.name}</p>
                                        <p>{cast.character}</p>
                                    </div>
                                ))
                            ) : (
                                <p>No cast information available.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Media;
