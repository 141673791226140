const questions = [
    {
        question: "What do you feel like watching?",
        key: "media_type",
        options: ["Movie", "TV Series", "Anime", "Documentary"],
        values: ["movie", "tv", "anime", "documentary"],
    },
    {
        question: "Do you want something new, or are you okay with a rewatch?",
        key: "new_or_rewatch",
        options: ["I want something new", "I don't mind a rewatch"],
        values: ["new", "rewatch"],
        condition: (answers) => ["movie", "tv", "anime", "documentary"].includes(answers.media_type),
        requiresLogin: true, // This question requires login
    },
    {
        question: "What provider are you subscribed to?",
        key: "provider",
        options: ["Netflix", "Disney+", "Prime Video", "I will be king of the pirates"],
        values: ["8", "9", "337", "all"], // "all" will signify no provider filter
        multiple: true // Add this property to indicate that multiple selections are allowed
    },
    // Questions for Movies
    {
        question: "Do you want an animated movie or a live-action movie?",
        key: "movie_type",
        options: ["Animated movie", "Live action"],
        values: ["animated", "live_action"],
        condition: (answers) => answers.media_type === "movie",
    },
    {
        question: "How much time do you have?",
        key: "movie_length",
        options: ["<1h45", "<2h45", "I don't have a preference"],
        values: ["105", "165", "no_preference"],  // Time in minutes (105 for 1h45, 165 for 2h45)
        condition: (answers) => answers.media_type === "movie",
    },
    {
        question: "What genre do you feel like watching ?",
        key: "genre",
        options: ["Action", "Adventure", "Comedy", "Drama", "Horror", "Romance", "Sci-Fi", "Surprise me"],
        values: ["28", "12", "35", "18", "27", "10749", "878", "surprise"],  // Correct genre IDs
        condition: (answers) => answers.media_type === "movie",
    },

    // Questions for TV Series
    {
        question: "What genre do you feel like watching ?",
        key: "tv_genre",
        options: ["Adventure", "Mystery", "Comedy", "Drama", "Horror", "Romance", "Sci-Fi", "Surprise me"],
        values: ["10759", "9648", "35", "18", "315058", "9673", "10765", "surprise"],
        condition: (answers) => answers.media_type === "tv",
    },
    {
        question: "Do you have a preference on the length of each episodes?",
        key: "show_length",
        options: ["<30mins", ">45mins", "I don't have a preference"],
        values: ["short", "long", "no_preference"],
        condition: (answers) => answers.media_type === "tv",
    },

    // Anime-related questions
    {
        question: "Do you prefer an anime movie or a serialized anime?",
        key: "anime_type",
        options: ["Movie", "Serialized Anime"],
        values: ["movie", "tv"],
        condition: (answers) => answers.media_type === "anime",
    },
    {
        question: "How much time do you have?",
        key: "anime_movie_length",
        options: ["<1h45", "<2h45", "I don't have a preference"],
        values: ["105", "165", "no_preference"],
        condition: (answers) => answers.media_type === "anime" && answers.anime_type === "movie",
    },
    {
        question: "Do you have a preference on the length of each episode?",
        key: "anime_show_length",
        options: ["<30mins", ">45mins", "I don't have a preference"],
        values: ["short", "long", "no_preference"],
        condition: (answers) => answers.media_type === "anime" && answers.anime_type === "tv",
    },
    {
        question: "What kind of Anime are you looking for?",
        key: "anime_subgenre",
        options: ["Shounen", "Seinen", "Isekai", "No idea"],
        values: ["207826", "195668", "237451", "surprise"],
        condition: (answers) => answers.media_type === "anime" && answers.anime_type === "tv",
    },
    // Broader Anime genres second
    {
        question: "What genre do you feel like watching?",
        key: "anime_genre",
        options: ["Adventure", "Comedy", "Drama", "Dark Fantasy", "Romance", "Sci-Fi", "Sports", "Surprise me"],
        values: ["10759", "35", "18", "177895", "9840", "10765", "6075", "surprise"],
        condition: (answers) => answers.media_type === "anime",
    },
    // Documentary-related questions
    {
        question: "How much time do you have?",
        key: "doc_length",
        options: ["<1h45", "<2h45", "I don't have a preference"],
        values: ["105", "165", "no_preference"],
        condition: (answers) => answers.media_type === "documentary",
    },
    {
        question: "What should it be about?",
        key: "doc_genre",
        options: ["Nature", "Society", "History", "Science", "Biography", "Politics", "Sports", "Surprise me"],
        values: ["221355", "161316", "321490", "325892", "5565", "239902", "159290", "surprise"],
        condition: (answers) => answers.media_type === "documentary",
    }
];

export default questions;
