import React from 'react';
import { Container, Typography } from '@mui/material';
import '../styles/Legal.css';

const Legal = () => {
    return (
        <div className="fadein">
            <Container maxWidth="sm" className="legal-container">
                <Typography variant="h4" gutterBottom className="legal-container-text">
                    Legal Notices
                </Typography>
                <Typography variant="body1" paragraph>
                    The content of this website, including but not limited to text, images, and code, is the property of Your Movie Oracle. Unauthorized use, reproduction, or distribution of this content is prohibited.
                </Typography>
                <Typography variant="body1" paragraph>
                    For any legal inquiries, please contact admin@yourmovieoracle.com.
                </Typography>
            </Container>
        </div>
    );
};

export default Legal;
