// src/theme.js
import { createTheme } from '@mui/material/styles';

const getDesignTokens = (mode) => ({
    palette: {
        mode, // Automatically switches between light and dark modes
        primary: {
            main: '#E50914', // Netflix red
        },
        secondary: {
            main: mode === 'light' ? '#1c1c1c' : '#FFFFFF', // Switches contrast
        },
        tertiary: {
            main: mode === 'light' ? '#fffbef' : '#1c1c1c', // Your custom tertiary color
        },
        background: {
            default: mode === 'light' ? '#fffbef' : '#1c1c1c', // Light or dark background
            paper: mode === 'light' ? '#fffbef' : '#1c1c1c', // Light or dark paper background
        },
        text: {
            primary: mode === 'light' ? '#000000' : '#FFFFFF', // Dark text for light mode, white text for dark mode
            secondary: mode === 'light' ? '#333333' : '#B3B3B3', // Light gray text in dark mode
        },
    },
    typography: {
        fontFamily: '"Montserrat", sans-serif',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '12px',
                    textTransform: 'none',
                    fontSize: '1rem',
                    transition: 'transform 0.2s',
                    '&:hover': {
                        transform: 'scale(1.02)',
                    },
                },
                containedPrimary: {
                    '&:hover': {
                        backgroundColor: mode === 'light' ? '#E50914' : '#B20610',
                    },
                },
                containedSecondary: {
                    '&:hover': {
                        backgroundColor: mode === 'light' ? '#CCCCCC' : '#ecbbbe',
                    },
                },
            },
        },
    },
});

// Create a function to generate the theme
export const getTheme = (mode) => createTheme(getDesignTokens(mode));
