import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../styles/Results.css';
import '../styles/Home.css';
import { useLoading } from '../context/LoadingContext';

const Results = ({ answers, visibleItems, setVisibleItems, userId, token }) => {
    const [results, setResults] = useState([]);
    const [isFetchingResults, setIsFetchingResults] = useState(true);
    const [seenMedia, setSeenMedia] = useState([]);
    const [genres, setGenres] = useState({});
    const userCountry = localStorage.getItem('userCountry') || 'US';
    const userLanguage = localStorage.getItem('userLanguage') || 'en';
    const { loading, setLoading } = useLoading();

    // Fetch results from the server
    const fetchResults = async () => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/quiz`,
                { ...answers, filteredWatchlist: seenMedia, limit: 50 },
                {
                    params: {
                        country: userCountry,
                        language: userLanguage,
                    },
                }
            );
            setResults(response.data);
            if (userId && token) {
                saveRecommendedMedia(response.data.slice(0, 5));
            }
        } catch (error) {
            console.error('Error fetching quiz results:', error);
        } finally {
            setLoading(false);
            setIsFetchingResults(false);
        }
    };

    // Save top 5 recommended media to "My Recommended" list
    const saveRecommendedMedia = async (mediaList) => {
        // Exit if user is not logged in
        if (!userId || !token) return;
        try {
            const promises = mediaList.map(media =>
                axios.post(`${process.env.REACT_APP_API_URL}/users/${userId}/recommended`, {
                    media_id: media.id,
                    media_type: media.media_type,
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                }).catch(error => {
                    if (error.response && error.response.status === 200) {
                        console.log("Media already recommended, skipping.");
                    } else {
                        console.error('Error saving recommended media:', error);
                    }
                })
            );
            await Promise.all(promises);
        } catch (error) {
            console.error('Error saving recommended media:', error);
        }
    };

    // Fetch genres and watch history
    useEffect(() => {
        const fetchWatchHistoryAndGenres = async () => {
            try {
                if (userId && token) {
                    const watchHistoryResponse = await axios.get(`${process.env.REACT_APP_API_URL}/users/${userId}/watch-history`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    const seenIds = watchHistoryResponse.data.map(media => media.media_id);
                    setSeenMedia(seenIds);
                }

                const genresResponse = await axios.get(`${process.env.REACT_APP_API_URL}/tmdb/genres`, {
                    params: { type: 'movie', language: userLanguage }
                });
                const genresMap = genresResponse.data.genres.reduce((map, genre) => {
                    map[genre.id] = genre.name;
                    return map;
                }, {});
                setGenres(genresMap);
            } catch (error) {
                console.error('Error fetching watch history or genres:', error);
            }
        };

        fetchWatchHistoryAndGenres();
        fetchResults();
    }, [answers, userId, token]);

    // Handle lazy loading on scroll
    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.scrollHeight - 200 && visibleItems < results.length) {
                setVisibleItems(prev => prev + 20);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [visibleItems, results.length]);

    if (isFetchingResults) {
        return (
            <div className="loader-container">
            </div>
        );
    }

    if (!results || results.length === 0) {
        return <Typography variant="body1" gutterBottom className='no-media'>Sorry, no media matches your choices.</Typography>;
    }

    return (
        <div className="fadein">
            <div className="results-grid">
                {results.slice(0, visibleItems).map((media, index) => (
                    <Link to={`/media/${media.id}?type=${media.media_type}`} key={media.id}>
                        <div className="results-card">
                            <img src={`https://image.tmdb.org/t/p/w500${media.poster_path}`} alt={media.title || media.name} className="results-poster" />
                            <div className="results-content">
                                <h6 className="results-title">{media.title || media.name}</h6>
                                <p className="results-release-date">{(media.release_date || media.first_air_date || '').slice(0, 4)}</p>
                                <p className="results-genre">{media.genre_ids.map(id => genres[id]).join(', ')}</p>
                            </div>
                            {seenMedia.includes(media.id) && <div className="seen-bubble">Seen!</div>}
                            {index < 5 && <div className="recommendation-bubble">Recommended</div>}
                        </div>
                    </Link>
                ))}
            </div>
        </div >
    );
};

export default Results;
