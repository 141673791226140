import React, { useState } from 'react';
import { TextField, Button, Container, Typography, IconButton, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { registerUser, loginUser, setAuthToken } from '../services/api';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import '../styles/Register.css';

const Register = ({ onLogin }) => {
    const [formData, setFormData] = useState({ username: '', email: '', password: '' });
    const [errorMessage, setErrorMessage] = useState(null);
    const [showPassword, setShowPassword] = useState(false); // Control password visibility
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const validatePassword = (password) => /^(?=.*[A-Z])(?=.*\d).{8,}$/.test(password);

    const toggleShowPassword = () => setShowPassword(!showPassword);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(null);

        if (!formData.username || !formData.email || !formData.password) {
            setErrorMessage("Please fill in all fields.");
            return;
        }

        if (!validateEmail(formData.email)) {
            setErrorMessage("Please enter a valid email address.");
            return;
        }

        if (!validatePassword(formData.password)) {
            setErrorMessage("Password must be at least 8 characters long, contain at least one uppercase letter, and one number.");
            return;
        }

        try {
            const registerResponse = await registerUser(formData);
            const loginResponse = await loginUser({ username: formData.username, password: formData.password });
            const { access_token, id, username } = loginResponse.data;

            localStorage.setItem('token', access_token);
            localStorage.setItem('username', username);
            localStorage.setItem('user_id', id);
            setAuthToken(access_token);

            onLogin({ access_token, username, id });
            navigate('/');
        } catch (error) {
            if (error.response && error.response.data) {
                if (error.response.data.error.includes("username")) {
                    setErrorMessage("This username is already taken.");
                } else if (error.response.data.error.includes("email")) {
                    setErrorMessage("This email is already registered.");
                } else {
                    setErrorMessage("An error occurred. Please try again.");
                }
            } else {
                setErrorMessage("Unable to connect to the server. Please try again later.");
            }
        }
    };

    return (
        <div className="fadein">
            <Container maxWidth="sm" className="register-container">
                <form onSubmit={handleSubmit} className="register-form">
                    <TextField
                        label="Username"
                        variant="outlined"
                        fullWidth
                        name="username"
                        onChange={handleChange}
                        sx={{ marginBottom: '20px' }}
                    />
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        name="email"
                        onChange={handleChange}
                        sx={{ marginBottom: '20px' }}
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'} // Toggle visibility
                        fullWidth
                        name="password"
                        onChange={handleChange}
                        sx={{ marginBottom: '20px' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={toggleShowPassword} edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button variant="contained" color="primary" type="submit" className="register-button">
                        Register
                    </Button>
                    {errorMessage && (
                        <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
                            {errorMessage}
                        </Typography>
                    )}
                </form>
            </Container>
        </div>
    );
};

export default Register;
