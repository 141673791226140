import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import HomeIcon from '../icons/home.svg';
import TrendingIcon from '../icons/trending.svg';
import UserIcon from '../icons/user.svg';
import '../styles/Navbar.css';

const Navbar = ({ isLoggedIn, username, onLogout }) => {
    const location = useLocation(); // Get current location
    const navigate = useNavigate(); // Hook for navigation

    // Function to handle page reloads if already on the target page
    const handlePageReload = (targetPath) => {
        if (location.pathname === targetPath) {
            window.location.reload(); // Reload the page
        }
    };

    // Function to handle Logout and redirect to login
    const handleLogoutClick = () => {
        onLogout(); // Clear user data (existing onLogout function)
        navigate('/login'); // Redirect to the login page
        window.location.reload(); // Refresh the page after logging out
    };

    return (
        <nav className="navbar">
            <div className="navbar-container">
                {/* Mobile Icons */}
                <ul className="navbar-icons">
                    <li>
                        <Link to="/" onClick={() => handlePageReload('/')}>
                            <img src={HomeIcon} alt="Home" className="navbar-icon" />
                            <span className="navbar-text">Home</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/trending" onClick={() => handlePageReload('/trending')}>
                            <img src={TrendingIcon} alt="Trending" className="navbar-icon trending-icon" />
                            <span className="navbar-text">Trending</span>
                        </Link>
                    </li>
                    <li>
                        {isLoggedIn ? (
                            <Link to={`/user/${username}`} onClick={() => handlePageReload(`/user/${username}`)}>
                                <img src={UserIcon} alt="User" className="navbar-icon" />
                                <span className="navbar-text">{username}</span>
                            </Link>
                        ) : (
                            <Link to="/login">
                                <img src={UserIcon} alt="Login" className="navbar-icon" />
                                <span className="navbar-text">Login</span>
                            </Link>
                        )}
                    </li>
                </ul>

                {/* Desktop Links */}
                <ul className="navbar-desktop-links">
                    {/* Left side: Home and Trending */}
                    <div className="navbar-left">
                        <li>
                            <Link to="/" onClick={() => handlePageReload('/')}>Home</Link>
                        </li>
                        <li>
                            <Link to="/trending" onClick={() => handlePageReload('/trending')}>Trending</Link>
                        </li>
                    </div>

                    {/* Right side: Login/Register or Username/Logout */}
                    <div className="navbar-right">
                        {isLoggedIn ? (
                            <>
                                <li>
                                    <Link to={`/user/${username}`} onClick={() => handlePageReload(`/user/${username}`)}>{username}</Link>
                                </li>
                                <li>
                                    <Link to="/" onClick={handleLogoutClick}>Logout</Link>
                                </li>
                            </>
                        ) : (
                            <>
                                <li>
                                    <Link to="/login">Login</Link>
                                </li>
                                <li>
                                    <Link to="/register">Register</Link>
                                </li>
                            </>
                        )}
                    </div>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
