export const countryList = [
    { code: "AF", name: "Afghanistan", language: "fa" },
    { code: "AL", name: "Albania", language: "sq" },
    { code: "DZ", name: "Algeria", language: "ar" },
    { code: "AS", name: "American Samoa", language: "en" },
    { code: "AD", name: "Andorra", language: "ca" },
    { code: "AO", name: "Angola", language: "pt" },
    { code: "AI", name: "Anguilla", language: "en" },
    { code: "AQ", name: "Antarctica", language: "en" },
    { code: "AG", name: "Antigua and Barbuda", language: "en" },
    { code: "AR", name: "Argentina", language: "es" },
    { code: "AM", name: "Armenia", language: "hy" },
    { code: "AW", name: "Aruba", language: "nl" },
    { code: "AU", name: "Australia", language: "en" },
    { code: "AT", name: "Austria", language: "de" },
    { code: "AZ", name: "Azerbaijan", language: "az" },
    { code: "BS", name: "Bahamas", language: "en" },
    { code: "BH", name: "Bahrain", language: "ar" },
    { code: "BD", name: "Bangladesh", language: "bn" },
    { code: "BB", name: "Barbados", language: "en" },
    { code: "BY", name: "Belarus", language: "be" },
    { code: "BE", name: "Belgium", language: "nl" },
    { code: "BZ", name: "Belize", language: "en" },
    { code: "BJ", name: "Benin", language: "fr" },
    { code: "BM", name: "Bermuda", language: "en" },
    { code: "BT", name: "Bhutan", language: "dz" },
    { code: "BO", name: "Bolivia", language: "es" },
    { code: "BA", name: "Bosnia and Herzegovina", language: "bs" },
    { code: "BW", name: "Botswana", language: "en" },
    { code: "BR", name: "Brazil", language: "pt" },
    { code: "BN", name: "Brunei", language: "ms" },
    { code: "BG", name: "Bulgaria", language: "bg" },
    { code: "BF", name: "Burkina Faso", language: "fr" },
    { code: "BI", name: "Burundi", language: "fr" },
    { code: "CV", name: "Cape Verde", language: "pt" },
    { code: "KH", name: "Cambodia", language: "km" },
    { code: "CM", name: "Cameroon", language: "fr" },
    { code: "CA", name: "Canada", language: "en" },
    { code: "KY", name: "Cayman Islands", language: "en" },
    { code: "CF", name: "Central African Republic", language: "fr" },
    { code: "TD", name: "Chad", language: "fr" },
    { code: "CL", name: "Chile", language: "es" },
    { code: "CN", name: "China", language: "zh" },
    { code: "CO", name: "Colombia", language: "es" },
    { code: "KM", name: "Comoros", language: "ar" },
    { code: "CG", name: "Congo", language: "fr" },
    { code: "CD", name: "Congo (DRC)", language: "fr" },
    { code: "CR", name: "Costa Rica", language: "es" },
    { code: "HR", name: "Croatia", language: "hr" },
    { code: "CU", name: "Cuba", language: "es" },
    { code: "CY", name: "Cyprus", language: "el" },
    { code: "CZ", name: "Czech Republic", language: "cs" },
    { code: "DK", name: "Denmark", language: "da" },
    { code: "DJ", name: "Djibouti", language: "fr" },
    { code: "DM", name: "Dominica", language: "en" },
    { code: "DO", name: "Dominican Republic", language: "es" },
    { code: "EC", name: "Ecuador", language: "es" },
    { code: "EG", name: "Egypt", language: "ar" },
    { code: "SV", name: "El Salvador", language: "es" },
    { code: "GQ", name: "Equatorial Guinea", language: "es" },
    { code: "ER", name: "Eritrea", language: "ti" },
    { code: "EE", name: "Estonia", language: "et" },
    { code: "ET", name: "Ethiopia", language: "am" },
    { code: "FJ", name: "Fiji", language: "en" },
    { code: "FI", name: "Finland", language: "fi" },
    { code: "FR", name: "France", language: "fr" },
    { code: "GA", name: "Gabon", language: "fr" },
    { code: "GM", name: "Gambia", language: "en" },
    { code: "GE", name: "Georgia", language: "ka" },
    { code: "DE", name: "Germany", language: "de" },
    { code: "GH", name: "Ghana", language: "en" },
    { code: "GR", name: "Greece", language: "el" },
    { code: "GD", name: "Grenada", language: "en" },
    { code: "GT", name: "Guatemala", language: "es" },
    { code: "GN", name: "Guinea", language: "fr" },
    { code: "GW", name: "Guinea-Bissau", language: "pt" },
    { code: "GY", name: "Guyana", language: "en" },
    { code: "HT", name: "Haiti", language: "fr" },
    { code: "HN", name: "Honduras", language: "es" },
    { code: "HU", name: "Hungary", language: "hu" },
    { code: "IS", name: "Iceland", language: "is" },
    { code: "IN", name: "India", language: "hi" },
    { code: "ID", name: "Indonesia", language: "id" },
    { code: "IR", name: "Iran", language: "fa" },
    { code: "IQ", name: "Iraq", language: "ar" },
    { code: "IE", name: "Ireland", language: "en" },
    { code: "IL", name: "Israel", language: "he" },
    { code: "IT", name: "Italy", language: "it" },
    { code: "JM", name: "Jamaica", language: "en" },
    { code: "JP", name: "Japan", language: "ja" },
    { code: "JO", name: "Jordan", language: "ar" },
    { code: "KZ", name: "Kazakhstan", language: "kk" },
    { code: "KE", name: "Kenya", language: "en" },
    { code: "KI", name: "Kiribati", language: "en" },
    { code: "KW", name: "Kuwait", language: "ar" },
    { code: "KG", name: "Kyrgyzstan", language: "ky" },
    { code: "LA", name: "Laos", language: "lo" },
    { code: "LV", name: "Latvia", language: "lv" },
    { code: "LB", name: "Lebanon", language: "ar" },
    { code: "LS", name: "Lesotho", language: "en" },
    { code: "LR", name: "Liberia", language: "en" },
    { code: "LY", name: "Libya", language: "ar" },
    { code: "LI", name: "Liechtenstein", language: "de" },
    { code: "LT", name: "Lithuania", language: "lt" },
    { code: "LU", name: "Luxembourg", language: "fr" },
    { code: "MG", name: "Madagascar", language: "mg" },
    { code: "MW", name: "Malawi", language: "en" },
    { code: "MY", name: "Malaysia", language: "ms" },
    { code: "MV", name: "Maldives", language: "dv" },
    { code: "ML", name: "Mali", language: "fr" },
    { code: "MT", name: "Malta", language: "mt" },
    { code: "MH", name: "Marshall Islands", language: "en" },
    { code: "MR", name: "Mauritania", language: "ar" },
    { code: "MU", name: "Mauritius", language: "fr" },
    { code: "MX", name: "Mexico", language: "es" },
    { code: "FM", name: "Micronesia", language: "en" },
    { code: "MD", name: "Moldova", language: "ro" },
    { code: "MC", name: "Monaco", language: "fr" },
    { code: "MN", name: "Mongolia", language: "mn" },
    { code: "ME", name: "Montenegro", language: "sr" },
    { code: "MA", name: "Morocco", language: "ar" },
    { code: "MZ", name: "Mozambique", language: "pt" },
    { code: "MM", name: "Myanmar", language: "my" },
    { code: "NA", name: "Namibia", language: "en" },
    { code: "NR", name: "Nauru", language: "en" },
    { code: "NP", name: "Nepal", language: "ne" },
    { code: "NL", name: "Netherlands", language: "nl" },
    { code: "NZ", name: "New Zealand", language: "en" },
    { code: "NI", name: "Nicaragua", language: "es" },
    { code: "NE", name: "Niger", language: "fr" },
    { code: "NG", name: "Nigeria", language: "en" },
    { code: "KP", name: "North Korea", language: "ko" },
    { code: "NO", name: "Norway", language: "no" },
    { code: "OM", name: "Oman", language: "ar" },
    { code: "PK", name: "Pakistan", language: "ur" },
    { code: "PW", name: "Palau", language: "en" },
    { code: "PA", name: "Panama", language: "es" },
    { code: "PG", name: "Papua New Guinea", language: "en" },
    { code: "PY", name: "Paraguay", language: "es" },
    { code: "PE", name: "Peru", language: "es" },
    { code: "PH", name: "Philippines", language: "tl" },
    { code: "PL", name: "Poland", language: "pl" },
    { code: "PT", name: "Portugal", language: "pt" },
    { code: "QA", name: "Qatar", language: "ar" },
    { code: "RO", name: "Romania", language: "ro" },
    { code: "RU", name: "Russia", language: "ru" },
    { code: "RW", name: "Rwanda", language: "fr" },
    { code: "SA", name: "Saudi Arabia", language: "ar" },
    { code: "SN", name: "Senegal", language: "fr" },
    { code: "RS", name: "Serbia", language: "sr" },
    { code: "SG", name: "Singapore", language: "en" },
    { code: "SK", name: "Slovakia", language: "sk" },
    { code: "SI", name: "Slovenia", language: "sl" },
    { code: "SB", name: "Solomon Islands", language: "en" },
    { code: "SO", name: "Somalia", language: "so" },
    { code: "ZA", name: "South Africa", language: "en" },
    { code: "ES", name: "Spain", language: "es" },
    { code: "LK", name: "Sri Lanka", language: "si" },
    { code: "SD", name: "Sudan", language: "ar" },
    { code: "SR", name: "Suriname", language: "nl" },
    { code: "SE", name: "Sweden", language: "sv" },
    { code: "CH", name: "Switzerland", language: "de" },
    { code: "SY", name: "Syria", language: "ar" },
    { code: "TW", name: "Taiwan", language: "zh" },
    { code: "TJ", name: "Tajikistan", language: "tg" },
    { code: "TZ", name: "Tanzania", language: "sw" },
    { code: "TH", name: "Thailand", language: "th" },
    { code: "TL", name: "Timor-Leste", language: "pt" },
    { code: "TG", name: "Togo", language: "fr" },
    { code: "TO", name: "Tonga", language: "en" },
    { code: "TT", name: "Trinidad and Tobago", language: "en" },
    { code: "TN", name: "Tunisia", language: "ar" },
    { code: "TR", name: "Turkey", language: "tr" },
    { code: "TM", name: "Turkmenistan", language: "tk" },
    { code: "UG", name: "Uganda", language: "en" },
    { code: "UA", name: "Ukraine", language: "uk" },
    { code: "AE", name: "United Arab Emirates", language: "ar" },
    { code: "GB", name: "United Kingdom", language: "en" },
    { code: "US", name: "United States", language: "en" },
    { code: "UY", name: "Uruguay", language: "es" },
    { code: "UZ", name: "Uzbekistan", language: "uz" },
    { code: "VU", name: "Vanuatu", language: "en" },
    { code: "VE", name: "Venezuela", language: "es" },
    { code: "VN", name: "Vietnam", language: "vi" },
    { code: "YE", name: "Yemen", language: "ar" },
    { code: "ZM", name: "Zambia", language: "en" },
    { code: "ZW", name: "Zimbabwe", language: "en" }
];
