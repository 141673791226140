import React, { useState, useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import '../styles/Home.css';

const Home = () => {
    const [isMobile, setIsMobile] = useState(false);
    const theme = useTheme()

    const hoverStyle = {
        color: theme.palette.mode === 'light' ? '#E50914' : '#cc4249', // Different colors for light and dark mode
        textShadow: theme.palette.mode === 'light'
            ? '0px 0px 4px #E50914' // Light mode shadow color
            : '0px 0px 8px #E50914, 0px 0px 12px #E50914, 0px 0px 16px #E50914' // Dark mode shadow color
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="fadein">
            <Container maxWidth="sm" className="home-container">
                <Typography className="home-title animate-title" style={{
                    textShadow: theme.palette.mode === 'light'
                        ? 'none' // Light mode shadow color
                        : '0px 0px 8px #E50914' // Dark mode shadow color
                }}>
                    Your Movie Oracle</Typography>
                <div className="intro-section">
                    <Link to="/quiz" className="home-text-link" style={{
                        color: theme.palette.mode === 'light' ? '#B20610' : '#cc4249',
                        textShadow: theme.palette.mode === 'light'
                            ? 'none' // Light mode shadow color
                            : '0px 0px 8px #E50914' // Dark mode shadow color
                    }}>
                        <Typography
                            className="home-text"
                            sx={{
                                "&:hover": hoverStyle
                            }}
                        >
                            Start the quiz!
                        </Typography>
                    </Link>
                </div>
            </Container>
        </div>
    );
};

export default Home;
