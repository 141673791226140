import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { countryList } from './countries';
import axios from 'axios';

const BetaNoticeModal = ({ open, onCountryChange, onLanguageChange, onFetchTrending }) => {
    const [selectedCountry, setSelectedCountry] = useState('US');
    const [selectedLanguage, setSelectedLanguage] = useState('en'); // Default to English

    const detectCountry = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/detect-country`);
            if (response.data && response.data.country) {
                return response.data.country;
            }
        } catch (error) {
            console.error("Error detecting country:", error);
        }
        return 'US';
    };

    useEffect(() => {
        const cachedCountry = localStorage.getItem('userCountry');

        if (cachedCountry) {
            setSelectedCountry(cachedCountry);
            const detectedLanguage = countryList.find(c => c.code === cachedCountry)?.language || 'en';
            setSelectedLanguage(detectedLanguage);
            onCountryChange(cachedCountry);
            onLanguageChange(detectedLanguage);
        } else {
            detectCountry().then((detectedCountry) => {
                setSelectedCountry(detectedCountry);
                const detectedLanguage = countryList.find(c => c.code === detectedCountry)?.language || 'en';
                setSelectedLanguage(detectedLanguage);
                onCountryChange(detectedCountry);
                onLanguageChange(detectedLanguage);
            });
        }
    }, []);

    const handleCountryChange = (event) => {
        const chosenCountry = event.target.value;
        const chosenLanguage = countryList.find(c => c.code === chosenCountry)?.language || 'en';

        setSelectedCountry(chosenCountry);
        setSelectedLanguage(chosenLanguage);
        onCountryChange(chosenCountry);
        onLanguageChange(chosenLanguage);
    };

    const handleContinue = () => {
        localStorage.setItem('userCountry', selectedCountry);
        localStorage.setItem('userLanguage', selectedLanguage);
        onCountryChange(selectedCountry);
        onLanguageChange(selectedLanguage);
        onFetchTrending();
    };

    return (
        <div className="fadein">
            <Modal open={open} disableEscapeKeyDown>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        p: 4,
                        borderRadius: '8px',
                    }}
                >
                    <Typography variant="h6" component="h2" gutterBottom>
                        Beta Version Notice
                    </Typography>
                    <Typography variant="body1" gutterBottom className='beta-notice-text'>
                        Our site is currently in beta. If you have any feedback, please reach out through the contact form in the settings.
                    </Typography>
                    <Typography variant="body1" gutterBottom className='beta-notice-instruction'>
                        Please select your country and language.
                    </Typography>
                    <Typography className="country-label">Country:</Typography>
                    <Select
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2, mb: 2 }}
                    >
                        {countryList.map((country) => (
                            <MenuItem key={country.code} value={country.code}>
                                {country.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <Button variant="contained" color="primary" onClick={handleContinue} fullWidth>
                        Continue
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default BetaNoticeModal;
