import React from 'react';
import { Container, Typography } from '@mui/material';
import '../styles/Legal.css';

const Accessibility = () => {
    return (
        <div className="fadein">
            <Container maxWidth="sm" className="legal-container">
                <Typography variant="h4" gutterBottom className="legal-container-text">
                    Accessibility Statement
                </Typography>
                <Typography variant="body1" paragraph>
                    Our goal is to ensure that everyone, including people with disabilities, has full access to all our services. We are continuously improving the accessibility of our platform to meet the required standards.
                </Typography>
                <Typography variant="body1" paragraph>
                    If you encounter any accessibility issues, please contact us at support@example.com. We are committed to resolving any accessibility barriers as soon as possible.
                </Typography>
            </Container>
        </div>
    );
};

export default Accessibility;
