import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Typography, Select, MenuItem } from '@mui/material';
import CogwheelIcon from '../icons/app-settings.svg';
import { useTheme } from '@mui/material/styles';
import '../styles/Footer.css';
import axios from 'axios';
import { countryList } from './countries';

const Footer = ({
    isLoggedIn,
    toggleTheme,
    themeMode,
    onFetchTrending,
    onCountryChange,
    onLanguageChange,
    selectedCountry,
    selectedLanguage,
}) => {
    const [openSettings, setOpenSettings] = useState(false);
    const [messageToAdmin, setMessageToAdmin] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [currentCountry, setCurrentCountry] = useState(selectedCountry);
    const [currentLanguage, setCurrentLanguage] = useState(selectedLanguage);
    const theme = useTheme();

    useEffect(() => {
        const selectedCountry = localStorage.getItem('userCountry');
        setCurrentCountry(selectedCountry);
    }, [selectedCountry, selectedLanguage]);

    const handleCountryChange = (event) => {
        const chosenCountry = event.target.value;
        const detectedLanguage = countryList.find(c => c.code === chosenCountry)?.language || 'en';

        setCurrentCountry(chosenCountry);
        setCurrentLanguage(detectedLanguage);

        // Update the state in App.js through props functions
        onCountryChange(chosenCountry);
        onLanguageChange(detectedLanguage);

        // Save to local storage
        localStorage.setItem('userCountry', chosenCountry);
        localStorage.setItem('userLanguage', detectedLanguage);

        // Fetch trending data with updated country and language
        onFetchTrending(chosenCountry, detectedLanguage);
    };


    const handleCloseSettings = () => {
        setOpenSettings(false);
    };

    const handleCogwheelClick = () => {
        setOpenSettings(true);
        if (isLoggedIn) {
            const storedEmail = localStorage.getItem('user_email');
            const storedName = localStorage.getItem('username');
            setEmail(storedEmail || '');
            setName(storedName || '');
        }
    };

    const handleSendMessageToAdmin = async () => {
        if (!messageToAdmin || (!isLoggedIn && (!name || !email))) {
            setErrorMessage('All fields are required.');
            return;
        }

        const endpoint = isLoggedIn
            ? `${process.env.REACT_APP_API_URL}/users/contact-admin`
            : `${process.env.REACT_APP_API_URL}/contact-admin`;

        const data = isLoggedIn
            ? { message: messageToAdmin }
            : { message: messageToAdmin, name, email };

        try {
            await axios.post(endpoint, data, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setSuccessMessage('Message sent to admin successfully.');
            setErrorMessage('');
            setMessageToAdmin('');
        } catch (error) {
            setErrorMessage('Failed to send the message.');
            setSuccessMessage('');
        }
    };

    return (
        <footer className="footer" style={{ backgroundColor: theme.palette.tertiary.main }}>
            <div className="footer-left">
                <Typography variant="body2" className="copyright-text">
                    © 2024 Your Movie Oracle
                </Typography>
            </div>
            <div className="footer-right">
                <img
                    src={CogwheelIcon}
                    alt="Settings"
                    className={`footer-cogwheel-icon ${themeMode === 'light' ? 'light-mode' : 'dark-mode'}`}
                    onClick={handleCogwheelClick}
                />
            </div>

            {/* Dialog for App Settings */}
            <Dialog open={openSettings} onClose={handleCloseSettings} maxWidth="md" fullWidth>
                <DialogTitle>App Settings</DialogTitle>
                <DialogContent>
                    <Button onClick={toggleTheme} className="switch-mode-button" variant="contained" color="primary">
                        Switch to {themeMode === 'light' ? 'Dark' : 'Light'} Mode
                    </Button>

                    {/* Message to Admin Section */}
                    <div className="admin-message-section">
                        <Typography variant="h6">Send a Message to Admin</Typography>
                        {!isLoggedIn && (
                            <>
                                <TextField
                                    label="Your Name"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <TextField
                                    label="Your Email"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </>
                        )}
                        <TextField
                            label="Message to Admin"
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={messageToAdmin}
                            onChange={(e) => setMessageToAdmin(e.target.value)}
                        />
                        <Button variant="contained" fullWidth className="send-message-button" onClick={handleSendMessageToAdmin}>
                            Send Message
                        </Button>
                        {successMessage && <Typography className="success-message">{successMessage}</Typography>}
                        {errorMessage && <Typography className="error-message">{errorMessage}</Typography>}
                    </div>

                    <div className="app-info-language-section">
                        <div className="app-info-section">
                            <Typography className="app-info-link">
                                <Link to="/privacy-policy" className="footer-link" onClick={() => setOpenSettings(false)}>Privacy Policy</Link>
                            </Typography>
                            <Typography className="app-info-link">
                                <Link to="/accessibility" className="footer-link" onClick={() => setOpenSettings(false)}>Accessibility</Link>
                            </Typography>
                            <Typography className="app-info-link">
                                <Link to="/terms-of-service" className="footer-link" onClick={() => setOpenSettings(false)}>Terms of Service</Link>
                            </Typography>
                            <Typography className="app-info-link">
                                <Link to="/cookies" className="footer-link" onClick={() => setOpenSettings(false)}>Cookies</Link>
                            </Typography>
                            <Typography className="app-info-link">
                                <Link to="/legal-notices" className="footer-link" onClick={() => setOpenSettings(false)}>Legal Notices</Link>
                            </Typography>
                        </div>

                        {/* Language and Country Selection */}
                        <div className="selection-section">
                            <div className="country-select-section">
                                <Typography className="country-label">Country:</Typography>
                                <Select
                                    value={currentCountry}
                                    onChange={handleCountryChange}
                                    variant="outlined"
                                    className="country-select"
                                >
                                    {countryList.map((country) => (
                                        <MenuItem key={country.code} value={country.code}>
                                            {country.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>

                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSettings} color="primary" className="close-button">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </footer>
    );
};

export default Footer;
