import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useSearchParams } from 'react-router-dom';
import '../styles/Trending.css';
import { useLoading } from '../context/LoadingContext';

const Trending = ({ language, onFetchTrending }) => {
    const [trending, setTrending] = useState([]);
    const [mediaType, setMediaType] = useState('movie');
    const [genres, setGenres] = useState({});
    const { loading, setLoading } = useLoading();
    const [seenMedia, setSeenMedia] = useState([]);
    const [currentParams, setCurrentParams] = useState({});
    const userCountry = localStorage.getItem('userCountry') || 'US';
    const userLanguage = localStorage.getItem('userLanguage') || 'en';
    const [searchParams, setSearchParams] = useSearchParams();

    const POLLING_INTERVAL = 1000; // Poll every 1s
    console.log('API URL:', process.env.REACT_APP_API_URL);

    useEffect(() => {
        const mediaParam = searchParams.get('type') || 'movie';
        const params = { mediaParam };

        if (JSON.stringify(params) !== JSON.stringify(currentParams)) {
            setMediaType(mediaParam);
            setCurrentParams(params);
            fetchGenres(mediaParam);
            startPollingTrendingData(mediaParam);
        }
    }, [searchParams, language]);

    useEffect(() => {
        const userId = localStorage.getItem('user_id');
        const token = localStorage.getItem('token');

        if (userId && token) {
            fetchSeenHistory(userId, token);
        }
    }, []);

    // Start polling to check if trending data is available
    const startPollingTrendingData = async (type) => {
        setLoading(true);

        // Call onFetchTrending to start data fetch on the backend
        if (onFetchTrending) {
            console.log("Calling onFetchTrending...");
            await onFetchTrending();
        }

        const pollData = async () => {
            try {
                console.log("Polling for trending data...");
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/display-trending`, {
                    params: {
                        type,
                        country: userCountry,
                        language: userLanguage,
                    },
                });

                if (response.data && response.data.length > 0) {
                    setTrending(response.data);
                } else {
                    // If no data, retry after the polling interval
                    setTimeout(pollData, POLLING_INTERVAL);
                }
            } catch (error) {
                console.error("Error fetching trending data:", error);
                setTimeout(pollData, POLLING_INTERVAL); // Retry after polling interval on error
            }
        };

        pollData(); // Start polling
        setLoading(false);
    };

    const fetchGenres = async (mediaType) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/tmdb/genres`, {
                params: { type: mediaType, language: userLanguage }
            });
            const genresMap = response.data.genres.reduce((acc, genre) => {
                acc[genre.id] = genre.name;
                return acc;
            }, {});
            setGenres(genresMap);
        } catch (error) {
            console.error("Error fetching genres:", error);
        }
    };

    const fetchSeenHistory = async (userId, token) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${userId}/watch-history`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const seenIds = response.data.map(media => media.media_id);
            setSeenMedia(seenIds);
        } catch (error) {
            console.error("Error fetching watch history:", error);
        }
    };

    const handleToggle = (type) => {
        setMediaType(type);
        const newParams = { type };
        setSearchParams(newParams);
    };

    return (
        <div className="fadein">
            <div className="trending-container">
                <div className="filter-panel">
                    <div className="media-type-box">
                        <h3>Filter by</h3>
                        <div className="media-type-filter">
                            <span
                                className={`filter-button ${mediaType === 'movie' ? 'active' : ''}`}
                                onClick={() => handleToggle('movie')}
                            >
                                Movies
                            </span>
                            <span
                                className={`filter-button ${mediaType === 'tv' ? 'active' : ''}`}
                                onClick={() => handleToggle('tv')}
                            >
                                Series
                            </span>
                        </div>
                    </div>
                </div>

                {loading ? (
                    <div className="loader-container">
                    </div>
                ) : (
                    <div className="fadein">
                        <div className="trending-grid-container">
                            <div className="trending-grid">
                                {Array.isArray(trending) && trending.length > 0 ? (
                                    trending.map((item) => (
                                        <Link to={`/media/${item.media_id}?type=${mediaType}`} key={item.media_id}>
                                            <div className="trending-card">
                                                <img
                                                    src={`https://image.tmdb.org/t/p/w500${item.poster_path}`}
                                                    alt={item.title || item.name}
                                                    className="trending-poster"
                                                />
                                                <div className="trending-content">
                                                    <h6 className="trending-title-text">{item.title || item.name}</h6>
                                                    <p className="trending-date">
                                                        {item.release_date ? new Date(item.release_date).getFullYear() : ""}
                                                    </p>
                                                    <p className="trending-genre">
                                                        {item.genre_ids.map((id) => genres[id]).join(", ")}
                                                    </p>
                                                </div>
                                                {/* Display "Seen!" bubble if the item is in the seenMedia list */}
                                                {seenMedia.includes(item.media_id) && (
                                                    <div className="seen-bubble">Seen!</div>
                                                )}
                                            </div>
                                        </Link>
                                    ))
                                ) : (
                                    <p>No trending data available.</p>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Trending;