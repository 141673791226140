import axios from 'axios';

// Set up the base URL for your API
const API_URL = process.env.REACT_APP_API_URL;

// Create an Axios instance
const api = axios.create({
    baseURL: API_URL,
});

// Function to set the JWT token in the headers// src/services/api.js

export const setAuthToken = (token, expiresIn = 86400000) => { // 24hour = 86400000 ms
    if (token) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        // Set token expiration time
        const expirationTime = new Date().getTime() + expiresIn;
        localStorage.setItem('tokenExpiration', expirationTime);
        console.log("Token expiration time set to:", new Date(expirationTime).toLocaleTimeString()); // Log expiration time
    } else {
        delete api.defaults.headers.common['Authorization'];
        localStorage.removeItem('tokenExpiration');
    }
};

// Interceptor for token expiration
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Unauthorized error, likely due to an expired token
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            localStorage.removeItem('user_id');

            // Optional: Redirect to login page or refresh the page to reset the app state
            window.location.reload(); // Ensures app state is reset
        }
        return Promise.reject(error);
    }
);

// Auth Endpoints
export const registerUser = async (userData) => {
    try {
        return await api.post('/register', userData);
    } catch (error) {
        console.error('API error:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const loginUser = async (userData) => {
    try {
        const response = await api.post('/login', userData);
        return response;
    } catch (error) {
        console.error('API error:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const logoutUser = async () => {
    try {
        const response = await api.post('/logout');
        return response;
    } catch (error) {
        console.error('API error:', error.response ? error.response.data : error.message);
        throw error;
    }
};
