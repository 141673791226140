import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LoadingProvider } from './context/LoadingContext';
import CssBaseline from '@mui/material/CssBaseline';
import axios from 'axios';
import Home from './pages/Home';
import Quiz from './pages/Quiz';
import Results from './pages/Results';
import Register from './pages/Register';
import Login from './pages/Login';
import Trending from './pages/Trending';
import Media from './pages/Media';
import Recommended from './pages/Recommended';
import Navbar from './components/Navbar';
import Profile from './pages/Profile';
import WatchLater from './pages/WatchLater';
import Privacy from './pages/Privacy';
import Legal from './pages/Legal';
import Cookies from './pages/Cookies';
import Tos from './pages/Tos';
import Accessibility from './pages/Accessibility';
import WatchHistory from './pages/WatchHistory';
import UserSettings from './pages/UserSettings';
import Footer from './components/Footer';
import { getTheme } from './theme';
import BetaNoticeModal from './components/BetaNoticeModal';
import './styles/Navbar.css';
import { setAuthToken } from './services/api';
import './index.css';


const PrivateRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    return token ? children : <Navigate to="/login" />;
};

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
    const [username, setUsername] = useState(localStorage.getItem('username') || '');
    const [themeMode, setThemeMode] = useState(localStorage.getItem('themeMode') || 'dark');
    const [showBetaModal, setShowBetaModal] = useState(true);
    const [userCountry, setUserCountry] = useState(localStorage.getItem('userCountry') || 'US');
    const [userLanguage, setUserLanguage] = useState(localStorage.getItem('userLanguage') || 'en');
    const [needsTrendingFetch, setNeedsTrendingFetch] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    // Fetch trending data from the API
    const fetchTrending = useCallback(async (country, language) => {
        if (isFetching) return;
        setIsFetching(true);
        try {
            const contentTypes = ['movie', 'tv'];
            let allTrendingData = [];

            for (const type of contentTypes) {
                let retryCount = 0;
                while (retryCount < 3) {
                    const trendingResponse = await axios.get(`${process.env.REACT_APP_API_URL}/tmdb/trending`, {
                        params: { type, country, language }
                    });
                    if (trendingResponse.status === 200) {
                        allTrendingData.push(...trendingResponse.data);
                        break;
                    } else {
                        retryCount += 1;
                        await new Promise(res => setTimeout(res, 1000));
                    }
                }
            }

            const validData = allTrendingData.map(item => ({
                media_id: item.id,
                media_type: item.media_type,
                title: item.title || item.original_title || "Untitled",
                poster_path: item.poster_path || null,
                language: language,
                genre_ids: item.genre_ids || [],
                release_date: item.release_date || null,
            })).filter(item => item.media_id && item.language);

            if (validData.length > 0) {
                await axios.post(`${process.env.REACT_APP_API_URL}/api/store-trending`, {
                    country,
                    data: validData
                });
                console.log("Trending data stored successfully.");
            } else {
                console.warn("No valid data to display.");
            }
        } catch (error) {
            console.error("Error fetching or storing trending data:", error);
        } finally {
            setIsFetching(false);
            setNeedsTrendingFetch(false);
        }
    }, [isFetching]);

    // Check if trending data needs to be fetched when the modal closes
    const handleFetchTrending = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/check-trending`, {
                params: { country: userCountry, language: userLanguage }
            });

            if (response.data.exists) {
                console.log(`Trending data for ${userCountry} and ${userLanguage} already exists. Skipping fetch.`);
                setShowBetaModal(false);
                return;
            }

            setShowBetaModal(false);
            setNeedsTrendingFetch(true);
        } catch (error) {
            console.error("Error checking if trending data exists:", error);
        }
    };

    useEffect(() => {
        if (needsTrendingFetch && !isFetching) {
            fetchTrending(userCountry, userLanguage);
            setNeedsTrendingFetch(false);
        }
    }, [needsTrendingFetch, fetchTrending, userCountry, userLanguage]);

    const handleLogin = (user) => {
        if (user.access_token && user.username && user.id) {
            console.log("Setting user ID:", user.id);  // Debugging line to confirm user_id
            localStorage.setItem('token', user.access_token);
            localStorage.setItem('username', user.username);
            localStorage.setItem('user_id', user.id);
            setAuthToken(user.access_token);
            setIsLoggedIn(true);
            setUsername(user.username);
        } else {
            console.error("Invalid login response: Missing token or user ID");  // Added error handling
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('user_id');
        localStorage.removeItem('tokenExpiration');
        setAuthToken(null);
        setIsLoggedIn(false);
        setUsername('');
        console.log("Logged out due to token expiration.");
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            const expirationTime = localStorage.getItem('tokenExpiration');
            const currentTime = new Date().getTime();

            if (expirationTime) {
                console.log("Current time:", new Date(currentTime).toLocaleTimeString());
                console.log("Token expiration time:", new Date(parseInt(expirationTime, 10)).toLocaleTimeString());

                if (currentTime > expirationTime) {
                    console.log("Token has expired. Triggering logout.");
                    handleLogout();
                }
            }
        }, 60000); // Check every minute

        return () => clearInterval(intervalId); // Clear interval on unmount
    }, []);

    const toggleTheme = () => {
        const newMode = themeMode === 'light' ? 'dark' : 'light';
        setThemeMode(newMode);
        localStorage.setItem('themeMode', newMode);
    };

    return (
        <ThemeProvider theme={getTheme(themeMode)}>
            <CssBaseline />
            <LoadingProvider>
                <Router>
                    <div className="app-container" data-testid="app-container">
                        <Navbar isLoggedIn={isLoggedIn} username={username} onLogout={handleLogout} />
                        <div className="content-container">
                            <Routes>
                                <Route path="/" element={<Home isLoggedIn={isLoggedIn} toggleTheme={toggleTheme} themeMode={themeMode} />} />
                                <Route path="/register" element={isLoggedIn ? <Navigate to="/" /> : <Register onLogin={handleLogin} />} />
                                <Route path="/login" element={isLoggedIn ? <Navigate to="/" /> : <Login onLogin={handleLogin} />} />
                                <Route path="/trending" element={<Trending onFetchTrending={handleFetchTrending} userCountry={userCountry} />} />
                                <Route path="/media/:mediaId" element={<Media language={userLanguage} />} />
                                <Route path="/privacy-policy" element={<Privacy />} />
                                <Route path="/accessibility" element={<Accessibility />} />
                                <Route path="/terms-of-service" element={<Tos />} />
                                <Route path="/cookies" element={<Cookies />} />
                                <Route path="/legal-notices" element={<Legal />} />
                                <Route path="/quiz" element={<Quiz />} />
                                <Route path="/results" element={<Results />} />
                                <Route path="/user/:username" element={<PrivateRoute><Profile username={username} onLogout={handleLogout} /></PrivateRoute>} />
                                <Route path="/watchlist" element={<PrivateRoute><WatchLater /></PrivateRoute>} />
                                <Route path="/history" element={<PrivateRoute><WatchHistory /></PrivateRoute>} />
                                <Route path="/recommended" element={<PrivateRoute><Recommended /></PrivateRoute>} />
                                <Route path="/user-settings/:username" element={<PrivateRoute><UserSettings /></PrivateRoute>} />
                            </Routes>
                        </div>
                        <Footer
                            isLoggedIn={isLoggedIn}
                            toggleTheme={toggleTheme}
                            themeMode={themeMode}
                            onFetchTrending={(country, language) => fetchTrending(country, language)}
                            onCountryChange={(country) => {
                                setUserCountry(country);
                                localStorage.setItem('userCountry', country);
                            }}
                            onLanguageChange={(language) => {
                                setUserLanguage(language);
                                localStorage.setItem('userLanguage', language);
                            }}
                            selectedCountry={userCountry}
                            selectedLanguage={userLanguage}
                        />
                    </div>
                </Router>
                <BetaNoticeModal
                    open={showBetaModal}
                    onCountryChange={(country) => {
                        setUserCountry(country);
                        localStorage.setItem('userCountry', country);
                    }}
                    onLanguageChange={(language) => {
                        setUserLanguage(language);
                        localStorage.setItem('userLanguage', language);
                    }}
                    onFetchTrending={handleFetchTrending}
                />
            </LoadingProvider>
        </ThemeProvider>
    );
}

export default App;
