import React from 'react';
import { Container, Typography } from '@mui/material';
import '../styles/Legal.css';

const Tos = () => {
    return (
        <div className="fadein">
            <Container maxWidth="sm" className="legal-container">
                <Typography variant="h4" gutterBottom className="legal-container-text">
                    Terms of Service
                </Typography>
                <Typography variant="body1" paragraph>
                    By using our services, you agree to these terms. Please read them carefully. These terms govern your use of our platform, including the content, features, and functionality available.
                </Typography>
                <Typography variant="body1" paragraph>
                    We reserve the right to update or modify these terms at any time, and it is your responsibility to review them periodically. Your continued use of our services indicates acceptance of any changes.
                </Typography>
            </Container>
        </div>
    );
};

export default Tos;
